import React from 'react'
import { projectLists, projectPage } from '../Constants';

export default function Projects({toggle}) {
  return (
    <div className={toggle? 'pointer-events-none': ''}>
    <div name="projects" className="w-full min-h-screen bg-[#EBE8E7] text-gray-500 ">
      <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center items-center w-full h-screen xxs:h-full xs:h-full sm:h-full md:h-full">
        <div className="pb-8 justify-center items-center text-center">
          <p className="text-xl md:text-4xl font-bold text-[#7A77B9]">{projectPage.title}</p>
          <p className="py-6 text-sm md:text-base xxs:text-xs text-center">{projectPage.caption}</p>
        </div>
        <div className="grid sm:grid-col-2 md:grid-cols-2 gap-8 px-8 sm:px-0">
          {
            projectLists.map(({ id, src, link, child, child1, link2 }) => (
              <div key={id}
                className="shadow-md shadow-gray-600 rounded-lg  duration-200 md:hover:scale-105">
                <button className="py-1 m-1 duration-200 hover:scale-105"><a href={link2} target="_blank" rel="noreferrer">{child}</a></button>
                <button className="py-1 m-1  duration-200 hover:scale-105"><a href={link} target="_blank" rel="noreferrer">{child1}</a></button> 
                <img src={src} alt=""/>         
              </div>
            ))
          }
        </div>
      </div>
    </div>
    </div>
  );
}
