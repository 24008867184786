import React, { useState } from 'react'
import { FaBars, FaTimes } from 'react-icons/fa'
import { Link } from "react-scroll"
import Logo from "../Assets/Logo.png"
import { navlinks } from '../Constants'

export default function Navbar({onToggle}) {
    const [toggle, setToggle] = useState(false);

    const toggleNavbar = () => {
        document.body.style.overflow = toggle ? 'auto' : 'hidden';
        const newToggle = !toggle;
        setToggle(newToggle);
        onToggle(newToggle); // Pass the new toggle value to the parent component
    };
    return (
            <div className="shadow-md flex justify-between items-center w-full h-20 px-4 text-[#7A77B9] fixed inset-0 bg-[#EBE8E7]" >
                <div className="md:px-10 py-4 px-7 md:flex justify-between items-center">
                    <Link to="home" smooth duration={500} class="ease-in-out">
                        <img src={Logo} className="mx-auto cursor-pointer max-h-24 md:max-h-30" alt="doma" />
                    </Link>
                </div>
                <ul className="hidden md:flex">
                    {navlinks.map(({ id, link }) => (
                        <li key={id} className=" px-4 cursor-pointer capitalize font-medium hover:scale-105 duration-100 text-[#7A77B9]">
                            <Link to={link} smooth duration={500}>{link}</Link>
                        </li>
                    ))}
                </ul>
                <div onClick={toggleNavbar} className="cursor-pointer pr-4 z-30 text-[#7A77B9] md:hidden">
                    {toggle ? <FaTimes size={30} color='#EBE8E7' /> : <FaBars size={30} />}
                </div>
                {toggle && (
                    <ul className="flex flex-col justify-center items-center absolute top-0 right-0 w-1/2 h-screen rounded-s bg-[#f98094] md:hidden">
                        {navlinks.map(({ id, link }) => (
                            <li key={id} className="px-4 cursor-pointer capitalize py-6 text-xl xxs:text-xs text-[#EBE8E7] ease-in-out">
                                <Link to={link} smooth duration={500}>{link}</Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
    )
}


