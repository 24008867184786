import React from 'react'
import {techstack, technologiesPage } from '../Constants'

export default function Technologies({toggle}){
    return (
        <div className={toggle? 'pointer-events-none': ''}>
        <div name="technologies" className="bg-[#EBE8E7] w-full min-h-screen">
            <div className="max-w-screen-lg p-4 mx-auto flex flex-col justify-center items-center w-full h-screen xxs:h-full xs:h-full sm:h-full md:h-full">
                <div className="pb-8 pl-4 text-center">
                    <p className="text-xl md:text-4xl inline font-bold p-2 text-[#7A77B9]">{technologiesPage.title}</p>
                    <p className="py-6 text-center text-sm text-gray-500 xxs:text-xs">{technologiesPage.caption}</p>
                </div>
                <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-8 text-center text-sm py-8 px-2 sm:px-0 text-gray-500 xxs:text-xs">
                    {techstack.map(({ id, src, title, style}) => (
                            <div
                             key={id} className={`shadow-md md:hover:scale-105 duration-500 py-2 rounded-lg ${style}`}>             
                             <img src={src} alt="" className="w-10 md:w-20 mx-auto" />
                             <p className="mt-4">{title}</p>
                            </div>
                      ))}
                </div>
            </div>
        </div> 
        </div> 
    )
}

