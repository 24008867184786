import React from 'react'
import { footerlinks, footerPage } from '../Constants'

export default function Footer({ toggle }) {
  return (
    <div className={toggle ? 'pointer-events-none' : ''}>
      <footer className="bottom-0 left-0 right-0  text-white bg-[#BD9DEA] md:bg-[#EBE8E7] py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:hidden md:hidden">
            <ul className="flex flex-wrap justify-center">
              {footerlinks.map(({ id, child, href, style, download }) => (
                <li key={id} className="px-4  grid grid-row-4  text-white mb-4">
                  <a href={href} className="flex justify-between items-center w-full" download={download} target="_blank" rel="noreferrer">
                    {child}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-wrap justify-center items-center ">
            <p className=" text-xs text-white md:text-gray-400 md:text-base ">{footerPage.signature}</p>
          </div>
        </div>
      </footer>
    </div>
  )

}
