import React from 'react'
import { contactPage } from '../Constants';


export default function Contact({toggle}) {
  
  return (
    <div className={toggle? 'pointer-events-none': ''}>
    <div name="contact" className=" w-full min-h-screen bg-[#EBE8E7] ">
      <div className="flex flex-col p-4 justify-center text-center max-w-screen-lg mx-auto h-full xxs:h-full xs:h-full sm:h-full md:h-full">
        <div className="pb-8">
          <p className="text-xl md:text-4xl font-bold inline text-[#7A77B9] ">{contactPage.title}</p>
          <p className="py-6 text-gray-500 text-sm md:text-base xxs:text-xs">{contactPage.caption}</p>
        </div>
        <div className="flex justify-center items-center">
          <form action="https://getform.io/f/adba1857-327a-4c48-a672-7dbc393dec86" method="POST" className=" flex flex-col w-full md:w-1/2">
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="p-2 bg-transparent border-2 border-gray-300 rounded-md text-gray-500 focus-within: outline-none text-sm md:text-base"
              required="true"/>
            <input
              type="text"
              name="email"
              placeholder="youremail@example.com"
              required="true" 
              className="my-4 p-2 bg-transparent border-2 border-gray-300 rounded-md text-gray-500 focus-within: outline-none text-sm md:text-base" />
            <textarea
              name="message"
              placeholder='Enter your message here'
              rows="10"
              required="true"
              className="p-2 bg-transparent border-2  border-gray-300 rounded-md text-gray focus: outline-none text-sm md:text-base">
            </textarea>
            <button className="text-white xxs:text-xs bg-purple-400 px-6 py-3 my-8 mx-auto flex-items-center rounded-md md:hover:scale-110 duration-300 text-sm md:text-base">
            {contactPage.buttonName}
            </button>
          </form>
        </div>
      </div>
    </div>
    </div>
  );
}
