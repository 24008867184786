import html from "../Assets/html.png"
import css from "../Assets/css.png"
import javascript from "../Assets/javascript.png"
import reactImage from "../Assets/react.png"
import github from "../Assets/github.png"
import tailwind from "../Assets/tailwind.png"
import bootstrap from "../Assets/bootstrap.png"
import typescript from "../Assets/Typescript.png"
import git from "../Assets/Git.png"
import sumanwebsite from "../Assets/sumanwebsite.png"
import domaWebsite from "../Assets/domaWebsite.png"
import {FaExternalLinkAlt } from 'react-icons/fa'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { BsFillPersonLinesFill } from 'react-icons/bs'

export const navlinks = [
    {
        id: 1,
        link: 'home'
    },
    {
        id: 2,
        link: 'about'
    },
    {
        id: 3,
        link: 'technologies'
    },
    {
        id: 4,
        link: 'projects'
    },
    {
        id: 5,
        link: 'contact'
    },
];
export const homePage = {
    myName: 'Doma Lama,',
    intro:
      ' I incorporate my creativity and my programming skill to design and build responsive websites.',
    position: 'a Front-end Developer.',
    greeting: 'Hi, My name is',
    link: 'View Work'
  };
 export const aboutPage = {
    title: 'About',
    intro:
      `I am a passionate React Developer with a strong focus on creating engaging and dynamic user interfaces. With a background in front-end development, I specialize in building interactive web applications that exceed user expectations.
      My journey in web development began with a curiosity about website construction and a drive to create meaningful online experiences.
      I stay updated with the latest trends and technologies, thrive in collaborative environments, and enjoy working closely with designers and back-end developers.
      Whether it's revamping a website or building a new web application, I am committed to turning visions into reality.`,
    greeting: ' Hi, I am Doma. Nice to meet you.',
  };
  
  export const technologiesPage = {
    title: 'Technologies',
    caption:'Technologies I have worked with',
     greeting: ' Hi, I am Doma. Nice to meet you.',
  };
  export const techstack = [
    {
        id:1,
        src: html,
        title: 'HTML',
        style: 'shadow-orange-500'
    },
    {
        id:2,
        src: css,
        title: 'CSS',
        style: 'shadow-blue-500'
    },
    {
        id:3,
        src: javascript,
        title: 'JavaScript',
        style: 'shadow-yellow-500'
    },
    {
        id:4,
        src: reactImage,
        title: 'React',
        style: 'shadow-blue-600'
    },
    {
        id:5,
        src: tailwind,
        title: 'Tailwind',
        style: 'shadow-sky-400'
    },
    {
        id:6,
        src: github,
        title: 'GitHub',
        style: 'shadow-white-400'
    },
    {
        id:7,
        src: bootstrap,
        title: 'Bootstrap',
        style: 'shadow-indigo-400'
    },
    {
        id:8,
        src: typescript,
        title: 'TypeScript',
        style: 'shadow-blue-400'
    },
    {
        id:9,
        src: git,
        title: 'Git',
        style: 'shadow-orange-400'
    },
]
export const projectLists = [
    {
      id: 1,
      src: sumanwebsite,
      child: (
        <>
          <FaGithub size={15}/>
        </>
      ),
      child1: (
        <>
         <FaExternalLinkAlt size={15}/>
        </>
      ),
      link: 'https://regeetamang.com/',
      link2: 'https://github.com/lamad',
    },
    {
      id: 2,
      src: domaWebsite,
      child: (
        <>
          <FaGithub size={15} />
        </>
      ),
      child1: (
        <>
        <FaExternalLinkAlt size={15} />
        </>
      ),
      link: 'http://domalama.com',
      link2: 'https://github.com/lamad',
    }

  ]
  export const projectPage = {
    title: 'Projects',
    caption:'Check out some of my work',
  };
  export const contactPage = {
    title: 'Contact',
    caption:'Submit the form to contact me',
    buttonName:'Send Message'
  };
  export const footerPage = {
    signature: 'Developed by Doma Lama',
  };
  
  export const socialLinks = [
    {
        id: 1,
        child: (
            <>
                LinkedIn <FaLinkedin size={30} />
            </>
        ),
        href: 'https://linkedin.com',
        style: 'rounded-tr-md',
    },
    {
        id: 2,
        child: (
            <>
                Github <FaGithub size={30} />
            </>
        ),
        href: 'https://github.com/lamadoma2',
    },
    {
        id: 3,
        child: (
            <>
                Mail <HiOutlineMail size={30} />
            </>
        ),
        href: 'mailto:info@domalama.com',
    },
    {
        id: 4,
        child: (
            <>
                Resume <BsFillPersonLinesFill size={30} />
            </>
        ),
        href: '/DomaResume.pdf',
        style: 'rounded-br-md',
        download: true,
    }
]
export const footerlinks = [
    {
        id: 1,
        child: (
            <>
             <FaLinkedin size={17} />
            </>
        ),
        href: 'https://linkedin.com',
        style: 'rounded-tr-md',
    },
    {
        id: 2,
        child: (
            <>
             <FaGithub size={17} />
            </>
        ),
        href: 'https://github.com/lamadoma2',
    },
    {
        id: 3,
        child: (
            <>
             <HiOutlineMail size={17} />
            </>
        ),
        href: 'mailto:info@domalama.com',
    },
    {
        id: 4,
        child: (
            <>
             <BsFillPersonLinesFill size={17} />
            </>
        ),
        href: '/DomaResume.pdf',
        style: 'rounded-br-md',
        download: true,
    }
]