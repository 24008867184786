import Contact from './Components/Contact';
import About from './Components/About';
import Home from './Components/Home';
import Navbar from './Components/Navbar';
import SocialLinks from './Components/SocialLinks'
import Technologies from './Components/Technologies';
import Projects from './Components/Projects';
import Footer from './Components/Footer';
import { useState } from 'react';

export default function App() {
  const [toggle, setToggle] = useState(false);

  const handleToggle = (newToggle) => {
    setToggle(newToggle);
  };
  return (
    <div className={toggle? 'bg-black' : ''}>
      <Navbar onToggle={handleToggle} />
      <Home toggle={toggle} />
      <About toggle={toggle}  />
      <Technologies toggle={toggle} />
      <Projects toggle={toggle} />
      <Contact toggle={toggle} />
      <SocialLinks toggle={toggle} />
      <Footer toggle={toggle} />
    </div>
  );
}

