import React from 'react'
import { aboutPage } from '../Constants';


export default function About(toggle) {
  return (
    <div className={toggle ? 'pointer-events-none' : ''}>
      <div name="about" className="w-full min-h-screen bg-[#EBE8E7] ">
        <div className="p-4 max-w-screen-lg flex flex-col justify-center items-center w-full h-screen mx-auto xxs:h-full xs:h-full sm:h-full md:h-full">
          <div className="pb-8 pl-4 text-center">
            <p className="text-xl md:text-4xl text-center font-bold inline text-[#7A77B9]">{aboutPage.title}</p>
          </div>
          <div className='max-w-screen-lg w-full grid grid-cols-2 gap-8'>
          </div>
          <div className="max-w-screen-lg w-full grid md:grid-cols-2 gap-8 px-4 ">
            <div className="text-xl md:text-4xl font-bold text-[#F2C76E] text-justify">
              <p>{aboutPage.greeting}</p>
            </div>
            <div>
              <p className="text-gray-500 text-justify text-sm xxs:text-xs">{aboutPage.intro}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
