import React from 'react'
import {socialLinks} from '../Constants/index'

export default function SocialLinks({toggle}){
    return (
        <div className={toggle? 'pointer-events-none': ''}>
        <div className="hidden lg:flex flex-col top-[35%] left-0 fixed">
            <ul>
            {socialLinks.map(({id, child, href, style, download}) => (
                    <li key={id} className={"flex justify-between justify-items-center w-40 h-20 px-4 ml-[-100px] hover:ml-[-10px] hover:rounded-md duration-300 bg-[#f98094] text-white" + " " + style}>
                        <a href={href} className="flex justify-between items-center w-full text-white" download={download} target='_blank' rel="noreferrer">
                           {child}
                        </a>
                    </li>
                ))}

            </ul>
        </div>
        </div>
    )
}

