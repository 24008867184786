import React from 'react'
import { SlArrowRight } from 'react-icons/sl'
import { Link } from 'react-scroll'
import Doma from './Doma'
import { homePage } from '../Constants/index'

export default function Home({toggle}) {
  return (
    <div className={toggle? 'pointer-events-none': ''}>
    <div name="home" className="bg-[#EBE8E7] w-full min-h-screen pt-[30%] md:pt-0 xs:pt-[40%] xxs:pt-[40%]">
      <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-screen xxs:h-full xs:h-full px-4 md:flex-row">
        <div className="flex flex-col justify-center h-full pb-0 ">
          <p className="text-gray-500">{homePage.greeting}</p>
          <h1 className="text-3xl xxs:text-xl md:text-7xl font-bold text-[#7A77B9] text-justify">{homePage.myName}</h1>
          <h2 className="text-3xl xxs:text-xl md:text-7xl font-bold text-[#BD9DEA]">{homePage.position}
          </h2>
          <p className="py-4 max-w-md text-gray-500 text-sm xxs:text-xs text-justify">
         {homePage.intro}
          </p>
          <div>
            <Link to="projects" smooth duration={500} className="group text-white w-fit px-6 py-3 my-2 flex items-center rounded-md bg-purple-400 xxs:text-sm ease-in-out">
              {homePage.link}
              <span className="group-hover:rotate-90 duration-300">
                <SlArrowRight size={15} className="ml-1" />
              </span>
            </Link>
          </div>
        </div>
        <div>
          < Doma />
        </div>
      </div>
    </div>
</div>
  );
}


